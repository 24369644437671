import * as React from 'react';
import { useParams } from 'react-router-dom';

export interface DialInDetailsProps {
    
}
 
const DialInDetails: React.SFC<DialInDetailsProps> = () => {
    const { URLRoomName } = useParams();
    const phones = [
        { country: "United Kingdom", code: "GB", number: "+44 203 984 9844" },
        { country: "USA", code: "US", number: "+1 718 866 4614" },
        { country: "USA", code: "US", number: "+1 888 686 3653" },
        { country: "Austria", code: "", number: "+43 720 022981" },
        { country: "Brazil", code: "BR", number: "+556 12017 1549" },
        { country: "Canada", code: "CA", number: "+1 587 855 1318" },
        { country: "Chile", code: "CL", number: "+562 2840 1484" },
        { country: "Czech Republic", code: "CZ", number: "+420 910 880101" },
        { country: "Estonia", code: "", number: "+372 609 4102" },
        { country: "Finland", code: "FI", number: "+35 8753 26 4477" },
        { country: 'France', code: 'FR', number: '+33 1758 50 878'},
        { country: "Germany", code: "DE", number: "+49 30 25 555 323" },
        { country: "Hong Kong", code: "HK", number: "+852 3001 6551" },
        { country: "Mexico", code: "MX", number: "+52 55 1168 9973" },
        { country: "Peru", code: "PE", number: "+51 1 7060950" },
        { country: "Poland", code: "PL", number: "+48 22 124 49 59" },
        { country: "Russia", code: "RU", number: "+7 495 283 98 58" },
        { country: "Singapore", code: "SG", number: "+65 3138 6816" },
        { country: "South Africa", code: "ZA", number: "+278 72500 455" },
        { country: "South Korea", code: "KR", number: "+82 70 4732 5006" },
        { country: "Sweden", code: "SE", number: "+46 10 551 30 20" },
        { country: "Turkey", code: "TR", number: "+90 850 390 7512" },
        { country: "Ukraine", code: "", number: "+380 89 324 0624" }
      ];
    
      
    return ( (<div>
        <div ><h3>To join by phone: </h3><p></p><h4>Meeting code: {URLRoomName}</h4></div>
        {/* {phoneDataFunc()} */}
        {phones.map(phone => <div>  {phone.country}:  {phone.number} </div>)}
        </div>)
        
     );
}
 
export default DialInDetails;